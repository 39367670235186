<template>
<div class="table_tab">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane :key="key" v-for="(item,key) in TabData" :name="item.name" :label="item.label"></el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
export default {
  name: 'TableTab',
  props: ['TabData', 'defaultData'],
  data () {
    return {
      activeName: this.defaultData
    }
  },
  methods: {
    handleClick (tab) {
      this.$emit('tabSwitch', tab)
    }
  }
}
</script>

<style scoped lang="scss">
.table_tab{
  margin-top: 20px;
  :deep(.el-tabs__content){display: none;}
  :deep(.el-tabs--top){
    box-shadow: none;
    border: 0px solid #DCDFE6;
  }
}
</style>
